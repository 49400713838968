import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="table"
export default class extends Controller {
  connect() {
    document.addEventListener('turbo:load', () => {
      this.initializeTable();
    });
  }

  initializeTable() {
    $('#finance').bootstrapTable({
      url: '/finance/finance_data.json',              // URL for data loading
      classes: 'table-borderless',                 // CSS classes for the table
      search: false,                               // Disable search
      locale: 'cs-CZ',                             // Localization (Czech)
      sortable: true,                              // Enable column sorting
      pageList: [10, 25, 50, 100],                 // Page size options
      pageSize: 10,                                // Default page size
      pagination: true,                            // Enable pagination
      sidePagination: 'server',                    // Server-side pagination
      showFullscreen: true,                        // Enable fullscreen mode
      showExport: true,                            // Enable export functionality
      showToggle: true,                            // Enable view toggle
      toggle: 'table',                             // Selector for view toggle
      toolbar: '.toolbar',                         // Toolbar selector
      detailFormatter: 'detailFormatter',          // Formatter for detailed view
      showColumnsSearch: true,                     // Enable column search
      strictSearch: true,                          // Enable strict search
      filterControl: true,                         // Enable filter control
      clickToSelect: true,                         // Enable click-to-select rows
      showSearchClearButton: true,                 // Show clear search button
      mobileResponsive: true,                      // Mobile responsive support
      checkOnInit: true,                           // Check on initialization
      minWidth: 1200,                              // Minimum width of the table
      onLoadError: function (status, res) {        // Handle data load errors
        if (status != 500) {                       // Unauthorized access
          window.location.reload();                // Force page refresh
        }
      }
    });
  }
}
