import { Controller } from "@hotwired/stimulus"
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import moment from "moment"

am4core.useTheme(am4themes_animated)
moment.locale("cs")

export default class extends Controller {
  static values = { data: Object, nemo: Boolean }

  connect() {
    // Zamezí vytvoření grafu, pokud už existuje
    if (this.chart) return
    this.initializeChart()
  }

  disconnect() {
    // Odstraní graf při odpojení z Turbo frame
    console.log('disconnect')
    console.log(this.chart)
    if (this.chart) {
      this.chart.dispose()
      this.chart = null
    }
  }

  initializeChart() {
    const target = '#chart'
    const data = this.dataValue
    const nemo = this.nemoValue

    let content = {};
    //let point = moment().startOf('month');
    let point = moment().endOf('month');
    var valueMin;
    var valueMax;
    $.each(Object.keys(data['data']), function(i, currency) {
        if(content[currency] == null) content[currency] = {name: currency, data: []};
        
        $.each(data['dates'], function (i, date) {
            let tmp = {date: date};
            
            if (data['data'][currency][date] === undefined) {
                tmp[currency] = 0.0;
            } else {
                tmp[currency] = Number(data['data'][currency][date].toFixed(2));
            }
            if(valueMin){
                if(valueMin > tmp[currency]){
                    valueMin = tmp[currency];
                }
            }else{
                valueMin = tmp[currency];
            }
            if(valueMax){
                if(valueMax < tmp[currency]){
                    valueMax = tmp[currency];
                }
            }else{
                valueMax = tmp[currency];
            }
            if (moment(date).isAfter(point)) {
                tmp['style'] = '8,4';
                tmp['color'] = am4core.color('#f0f0f0');
            }
            content[currency]['data'].unshift(tmp);
        });
    });
    
    this.chart = am4core.create(
        document.querySelector(target),
        am4charts.XYChart
    );
    const chart = this.chart;

    chart.language.locale = am4lang_cs_CZ;
    
    // chart.legend = new am4charts.Legend();

    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.dateFormatter = new am4core.DateFormatter();
    dateAxis.dateFormats.setKey("day", "d. MMMM");
    dateAxis.dateFormats.setKey("month", "d. MMMM");
    dateAxis.periodChangeDateFormats.setKey("month", "d. MMMM YYYY");
    dateAxis.title.text = 'Datum';
    
    $.each(content, function(currency, spec) {
        const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        if(chart.yAxes.indexOf(valueAxis) !== 0){
            valueAxis.syncWithAxis = chart.yAxes.getIndex(0);
        }
        
        valueAxis.title.text = 'Hodnota';
        valueAxis.numberFormatter = new am4core.NumberFormatter();
        valueAxis.numberFormatter.numberFormat = "#,###.00";
        valueAxis.adjustLabelPrecision = false;
        //var diffValue = Math.round((Number(valueMax) - Number(valueMin)) / 2);
        //var computeMin = Math.round((Number(valueMin) - Number(diffValue) * 8) / 10) * 10;
        //var computeMax = Math.round((Number(valueMax) + Number(diffValue)) / 10) * 10;
        //if (computeMin < 0) { computeMin = 0 };

        var computeMin = Math.round((Number(valueMin) / 2));
        var computeMax = Number(valueMax) + (Number(valueMax) / 10);
        if (nemo) {
            computeMin = Number(valueMin) - (Number(valueMin) / 100);
            computeMax = Number(valueMax) + (Number(valueMin) - computeMin);
        }

        valueAxis.min = Math.round(computeMin);
        valueAxis.max = Math.round(computeMax);
        //console.log(computeMin + ' ' + computeMax + ' ' + Number(valueMax) + ' ' + Number(valueMin));
        valueAxis.strictMinMax = true;
        valueAxis.renderer.labels.template.adapter.add("text", function(val) {
            return val + " " + currency;
        });
        
        let series = chart.series.push(new am4charts.LineSeries());
        series.yAxis = valueAxis;
        series.stroke = am4core.color("rgb(255,204,0)");
        series.fill = am4core.color("rgb(255,204,0)");
        series.fillOpacity = 0.3;
        series.propertyFields.strokeDasharray = 'style';
        series.propertyFields.stroke = 'color';
        series.propertyFields.fill = 'color';

        // let fillModifier = new am4core.LinearGradientModifier();
        // fillModifier.opacities = [1, 0];
        // fillModifier.offsets = [0, 1];
        // fillModifier.gradient.rotation = 90;
        // series.segments.template.fillModifier = fillModifier;

        series.dataFields.valueY = currency;
        series.dataFields.dateX = "date";
        series.strokeWidth = 3;
        series.minBulletDistance = 10;
        series.tooltipText = "{valueY} " + currency;
        series.tooltip.pointerOrientation = "vertical";
        series.tooltip.background.cornerRadius = 20;
        series.tooltip.background.fillOpacity = 0.3;
        series.tooltip.label.padding(12,12,12,12);

        series.data = spec['data'];
    });

    chart.scrollbarX = new am4charts.XYChartScrollbar();
    chart.scrollbarX.series.push(chart.series.getIndex(0));

    chart.scrollbarY = new am4core.Scrollbar();
    
    chart.cursor = new am4charts.XYCursor();
    // chart.cursor.xAxis = dateAxis;
    // chart.cursor.snapToSeries = series;
  }
}
