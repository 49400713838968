import { Controller } from "@hotwired/stimulus"
import ApexCharts from 'apexcharts'
import cs from "apexcharts/dist/locales/cs.json";
import dayjs from 'dayjs/dayjs.min';
import dayOfYear from 'dayjs/plugin/dayOfYear';

dayjs.extend(dayOfYear)

export default class extends Controller {

    static targets = ["series"];

    connect() {
        const element = this.element;
        const nemo = this.element.getAttribute("data-nemo") === 'true';

        let target = this.element.querySelector('.chartbox');
        if (target != null) {
            target.remove();
        }

        target = document.createElement("div");
        target.classList.add('chartbox');
        element.appendChild(target);

        const type = element.getAttribute("data-chart");
        const data = element.getAttribute("data-data");

        let series = [];
        let labels = [];
        let allData = [];

        if (data != null) {
            const tmp = JSON.parse(data);
            if (tmp != null) {
                series = [{
                    name: 'Hodnota',
                    data: tmp.map(item => item.y)
                }];
                labels = tmp.map(item => item.x);
                allData = tmp.map(item => item.y);
            }
        }

        let valueMin = Math.min(...allData);
        let valueMax = Math.max(...allData);
        let computeMin = Math.round(valueMin * 0.5);
        let computeMax = valueMax + (valueMax / 10);

        if (nemo) {
            computeMin = valueMin - (valueMin / 100);
            computeMax = valueMax + (valueMin - computeMin);
        }

        computeMin = Math.ceil(computeMin / 1000) * 1000 - 1000;
        computeMax = Math.ceil(computeMax / 1000) * 1000;
        
        const allDates = labels.map(point => new Date(point).getTime());
        const minDate = Math.min(...allDates);
        const maxDate = Math.max(...allDates);

        const options = {
            chart: {
                events: {
                    zoomed: function (chartContext, { xaxis }) {
                        const minZoom = minDate; 
                        const maxZoom = maxDate; 
            
                        const newMin = Math.max(xaxis.min, minZoom);
                        const newMax = Math.min(xaxis.max, maxZoom);
                
                        if (xaxis.min !== newMin || xaxis.max !== newMax) {
                            chartContext.updateOptions({
                                xaxis: {
                                    min: newMin,
                                    max: newMax,
                                },
                            });
                        }
                    },
                    beforeResetZoom: function (chartContext) {
                        // Zajistí, že reset zoom vrátí osu X na správné hodnoty
                        chartContext.updateOptions({
                            xaxis: {
                                min: minDate,
                                max: maxDate,
                            },
                            yaxis: {
                                min: computeMin,
                                max: computeMax,
                            },
                        });
                    },
                },
                locales: [cs],
                defaultLocale: 'cs',
                type: type,
                zoom: {
                    enabled: true,
                    type: 'x',
                    autoScaleYaxis: true,
                    zoomedArea: {
                        fill: {
                            color: '#ffcc00',
                            opacity: 0.1
                        },
                        stroke: {
                            color: '#ffcc00',
                            opacity: 0.5,
                            width: 1
                        }
                    }
                },
                toolbar: {
                    show: true,
                    tools: {
                        download: false,
                    }
                },
                animations: {
                    enabled: false
                }
            },
            colors: ['#ffcc00'],
            fill: {
                colors: ['#ffcc00'],
            },
            stroke: {
                curve: 'monotoneCubic',
                width: 3
            },
            xaxis: {
                //range: new Date('2024-08-05').getTime() - new Date('2024-07-04').getTime(),
                //min: new Date('2024-07-04').getTime(),
                //max: new Date('2024-08-05').getTime(),
                type: 'datetime',
                categories: labels,
                title: {
                    text: 'Datum',
                    style: {
                        fontSize: '14px',
                        fontWeight: 'bold',
                        color: '#333'
                    },
                    offsetY: 5
                },
                labels: {
                    format: 'dd. MMMM yyyy',
                    style: {
                        fontSize: '12px',
                        fontWeight: 'normal',
                        color: '#666'
                    }
                }
            },
            tooltip: {
                x: {
                    format: 'dd. MMMM yyyy'
                }
            },
            yaxis: {
                title: {
                    text: 'Hodnota (CZK)',
                    style: {
                        fontSize: '14px',
                        fontWeight: 'bold',
                        color: '#333'
                    },
                    offsetX: -4
                },
                min: computeMin,
                max: computeMax,
                labels: {
                    formatter: function (value) {
                        return Math.round(Number(value)).toLocaleString("cs") + " CZK";
                    }
                },
            },
            dataLabels: {
                enabled: false
            },
            series: series,
            legend: {
                position: 'bottom'
            },
            noData: {
                text: 'Načítám data...'
            }
        };

        const chart = new ApexCharts(target, options);

        chart.render().then(() => {
        });
    }
}
