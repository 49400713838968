import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["password", "icon"];

  toggle() {
    if (this.passwordTarget.type === "password") {
      this.passwordTarget.type = "text";
      this.iconTarget.classList.replace("bi-eye-slash", "bi-eye");
    } else {
      this.passwordTarget.type = "password";
      this.iconTarget.classList.replace("bi-eye", "bi-eye-slash");
    }
  }
}
