import { Controller } from "@hotwired/stimulus";
import $ from "jquery";
import "bootstrap-table";

// Connects to data-controller="table"
export default class extends Controller {
  connect() {
    document.addEventListener('turbo:load', () => {
      this.initializeTable();
    });
  }

  initializeTable() {
    $('#equity').bootstrapTable({
      url: '/equity/trade_data.json',
      classes: 'table-borderless',
      search: false,
      locale: 'cs-CZ',
      sortable: true,
      pageList: [10, 25, 50, 100],
      pageSize: 10,
      pagination: true,
      sidePagination: 'server',
      showFullscreen: true,
      showExport: true,
      showToggle: true,
      toolbar: '.toolbar',
      detailFormatter: 'detailFormatter',
      showColumnsSearch: true,
      strictSearch: true,
      filterControl: true,
      clickToSelect: true,
      showSearchClearButton: true,
      mobileResponsive: true,
      checkOnInit: true,
      minWidth: 1200,
      onLoadError: function (status, res) {
        if (status != 500) {
          window.location.reload();
        }
      }
    });
  }
}
